import mainjson from '../config/main';
import { Level } from '../utils/LoginFunctions';

function checkIf(data) {
    console.log("check if",parseInt(Level()));
    if (parseInt(Level()) <= 0) {
        return true;
    }
    else {
        return false;
    }
}

function checkIfReversed(data) {
    console.log("check if",Level());
    if (Level() == '0') {
        console.log("check if true");
        return true;
    }
    else {
        console.log("check if false");
        return false;
    }
}

export default function GetData() {
        let data = {
        "apitype":"cmsusers",
        "id":"cmsuser_id",
        "subidname":"cmsuser_name",
        "options":["edit","delete","insert"],
        "nameSingle":"cms user",
        "nameMultiple":"cms users",
      
        "fields":{
            "field1":{
                "name":"Name",
                "field":"cmsuser_name",
                "type":"TextInput",
                "required":true,
                "list":true
            },
            "field2":{
                "name":"Email",
                "field":"cmsuser_email",
                "type":"TextInput",
                "required":true,
                "list":true
            },
            "field3":{
                "name":"Password",
                "field":"cmsuser_password",
                "type":"PasswordInput",
                
                "list,":false
            },
            "field4":{
                "name":"Access",
                "field":"cmsuser_level",
                "type":"DropDown",
                "options": {
                    "0":"admin",
                    "1":"subadmin",
                    "2":"limited user",
                    "3":"scanner"
                },
                "required":true,
                "list":true,
            },   
            
            "field5":{
                "name":"Client",
                "field":"cmsuser_usergroup_id",
                "emptyfirstoption":"All clients",
                "emptyfirstoptionvalue":"-1",
                "type":"DropDown",
                "options": "usergroups",
                "list":true,
                "if":checkIf
            }, 
            


        }
    }

    if (parseInt(Level()) > 0) {
        data.fields.field4.options = {
            "1":"subadmin",
            "2":"limited user",
            "3":"scanner"
        }
        console.log("check level! ", data)
    }

    return data;
}